<template>
  <v-app>
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
            lg="3"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="green darken-3"
                dark
                flat
              >
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="Email"
                    type="email"
                    prepend-icon="person"
                    required
                    autofocus
                  ></v-text-field>

                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    label="Password"
                    type="password"
                    prepend-icon="lock"
                    required
                  ></v-text-field>
                  <v-btn :disabled="!valid" class="mt-3" width="100%" :loading="loading" color="green darken-3" @click="handleSubmit">Sign in</v-btn>
                </v-form>
                <div align="right" class="mt-3">
                  <a @click="resetModal = true">Forgot your password ?</a>
                </div>
                <v-divider class="my-5"></v-divider>
                <v-btn width="100%" color="blue" @click="loginWithGoogle">
                  <v-icon left>mdi-google</v-icon>
                  Sign in with Google
                </v-btn>
                <v-divider class="my-5"></v-divider>
                <div align="center">
                  Don't have an account ?
                  <router-link to="/register">
                    <span>Sign up</span>
                  </router-link>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-snackbar :timeout="10000" v-model="snackbar" bottom right absolute :color="snackColor">
          {{error}}
        </v-snackbar>
        <v-dialog v-model="resetModal" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Change password</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="resetForm" v-model="resetFormValid">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="Email"
                  type="email"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="error" text @click="resetModal = false">Cancel</v-btn>
              <v-btn :disabled="!resetFormValid" color="success" text @click="sendReset">Reset password</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  metaInfo: {
    title: 'Login'
  },
  data () {
    return {
      valid: true,
      error: '',
      snackColor: 'error',
      snackbar: false,
      email: null,
      password: null,
      loading: false,
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password should be at least 6 characters'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      resetModal: false,
      resetFormValid: true
    };
  },
  methods: {
    sendReset () {
      if (this.$refs.resetForm.validate()) {
        firebase.auth().sendPasswordResetEmail(this.email).then(() => {
          this.error = 'A message to reset your password has been sent successfully';
          this.snackColor = 'success';
          this.snackbar = true;
        }).catch((error) => {
          this.error = error.message;
          this.snackColor = 'error';
          this.snackbar = true;
        }).finally(() => {
          this.resetModal = false;
        });
      }
    },
    loginWithGoogle () {
      let provider = new firebase.auth.GoogleAuthProvider();
      // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      firebase.auth().signInWithPopup(provider).then(credentials => {
        firebase.firestore().collection('users').doc(credentials.user.uid).get().then(value => {
          let expires = new Date();
          expires.setDate(expires.getDate() + 30);
          if (value.exists) {
            let docUpdate = {
              user: {
                uid: credentials.user.uid,
                displayName: credentials.user.displayName,
                email: credentials.user.email
              }
            };
            if (!value.data().plan) {
              docUpdate.plan = {
                type: 'Trial',
                createdAt: new Date(),
                modifiedAt: new Date(),
                expiresAt: expires
              };
            }
            value.ref.update(docUpdate).finally(() => {
              this.$router.replace('/');
            });
          } else {
            value.ref.set({
              enabled: false,
              user: {
                uid: credentials.user.uid,
                displayName: credentials.user.displayName,
                email: credentials.user.email
              },
              plan: {
                type: 'Trial',
                createdAt: new Date(),
                modifiedAt: new Date(),
                expiresAt: expires
              }
            }).finally(() => {
              this.$router.replace('/');
            });
          }
        });
      }).catch(error => {
        this.error = error.message;
        this.snackColor = 'error';
        this.snackbar = true;
      });
    },
    handleSubmit (e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        this.loading = true;
        firebase.auth().signInWithEmailAndPassword(this.email, this.password).catch((error) => {
          this.error = error.message;
          this.snackColor = 'error';
          this.snackbar = true;
        }).then(credentials => {
          firebase.firestore().collection('users').doc(credentials.user.uid).get().then(value => {
            let expires = new Date();
            expires.setDate(expires.getDate() + 30);
            if (value.exists) {
              let docUpdate = {
                user: {
                  uid: credentials.user.uid,
                  displayName: credentials.user.displayName,
                  email: credentials.user.email
                }
              };
              if (!value.data().plan) {
                docUpdate.plan = {
                  type: 'Trial',
                  createdAt: new Date(),
                  modifiedAt: new Date(),
                  expiresAt: expires
                };
              }
              value.ref.update(docUpdate).finally(() => {
                this.$router.replace('/');
              });
            } else {
              value.ref.set({
                enabled: false,
                user: {
                  uid: credentials.user.uid,
                  displayName: credentials.user.displayName,
                  email: credentials.user.email
                },
                plan: {
                  type: 'Trial',
                  createdAt: new Date(),
                  modifiedAt: new Date(),
                  expiresAt: expires
                }
              }).finally(() => {
                this.$router.replace('/');
              });
            }
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    }
  }
};
</script>
